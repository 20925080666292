@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;

@use '../../../node_modules/sanitize.css/sanitize.css';
@use '../../../node_modules/sanitize.css/forms.css';

* {
  padding: 0;
  margin: 0;
  line-height: 1;
  color: #111;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

li {
  list-style-type: none;
}

th {
  font-weight: inherit;
}

address {
  font-style: inherit;
}

fieldset {
  min-width: 0;
  padding: 0;
  border: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

img {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

button,
input,
select,
textarea {
  padding: 0;
  border: 0;
}

button:not(:disabled):not([aria-disabled='true']) {
  cursor: pointer;
}

html {
  font-size: 62.5%;
}

body {
  height: 100vh;
  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium',
    '游ゴシック', 'Yu Gothic', sans-serif;
  font-weight: 400;
  font-size: 16px;
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
  overflow: hidden;
}

.pc {
  @include r.mq(mdless) {
    display: none !important;
  }
}

.sp {
  @include r.mq(mdover) {
    display: none !important;
  }
}

.inner {
  max-width: 1280px;
  padding: 0 40px;
  margin: 0 auto;
  @include r.sp {
    padding: 0 30px;
  }
}

.nolink {
  pointer-events: none;
}
