@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.l-contact {
  background: rgba($color: #fff, $alpha: 0.8);
  padding: 160px 0;
  @include r.sp {
    padding: 60px 0;
  }
  .c-contact {
    &__container {
      background: #f6f8f8;
      padding: 50px;
      margin-top: 80px;
      @include r.sp {
        padding: 25px;
        margin-top: 27px;
      }
    }
    &__txt {
      font-size: 1.6rem;
      line-height: calc(28 / 16);
      &1 {
        margin-top: 15px;
      }
      a {
        color: #c8161e;
        text-decoration: underline;
      }
    }
  }
}

.l-footer {
  background: #37474f;
  padding: 80px 0;
  @include r.sp {
    padding: 60px;
  }
  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    position: relative;
    @include r.sp {
      flex-direction: column;
      gap: 20px;
    }
  }
  &__logo {
    width: 80px;
    @include r.sp {
      width: 60px;
    }
  }
  &__link {
    display: flex;
    gap: 30px;
    @include r.sp {
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
    }
  }
  &__item {
    font-size: 1.6rem;
    font-weight: bold;
    line-height: calc(28 / 16);
    color: rgba($color: #fff, $alpha: 0.5);
    a {
      position: relative;
      &:hover {
        &:after {
          content: '';
          width: 100%;
          height: 1px;
          background-color: rgba($color: #fff, $alpha: 0.5);
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }
    }
  }
  &__copyright {
    font-family: 'Barlow', sans-serif;
    font-size: 1.2rem;
    line-height: calc(18 / 12);
    color: #fff;
    opacity: 0.5;
    position: absolute;
    bottom: 0;
    right: 40px;
    @include r.sp {
      text-align: center;
      position: initial;
    }
  }
}
.toPageTop {
  display: none;
  width: 60px;
  height: 60px;
  border: 1px solid rgba($color: #111, $alpha: 0.5);
  border-radius: 50%;
  position: fixed;
  bottom: 40px;
  right: 40px;
  transition: all 0.4s;
  @include r.sp {
    width: 50px;
    height: 50px;
    bottom: 16px;
    right: 16px;
  }
  &:hover {
    background: #c8161e;
    border-color: #c8161e;
    .off {
      display: none;
    }
    .on {
      display: block;
    }
  }
  img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &.off {
      display: block;
    }
    &.on {
      display: none;
    }
  }
}
