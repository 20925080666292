@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

// slash下
.c-ttl1 {
  font-family: 'Barlow', sans-serif;
  font-size: 9.6rem;
  color: #c8161e;
  @include r.sp {
    font-size: 4rem;
  }
  &__ja {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: calc(36 / 24);
    letter-spacing: 0.05em;
    color: #111;
    margin-top: 20px;
    @include r.sp {
      font-size: 2rem;
      line-height: calc(3 / 2);
      margin-top: 10px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 24px;
      background-image: url(/assets/images/common/slash.svg);
      background-repeat: no-repeat;
      background-size: contain;
      @include r.sp {
        width: 16px;
        height: 20px;
      }
    }
  }
}

// slash上
.c-ttl2 {
  &__en {
    display: inline-block;
    font-family: 'Barlow', sans-serif;
    font-size: 2rem;
    color: #c8161e;
    margin-bottom: 16px;
    @include r.sp {
      margin-bottom: 9px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 12px;
      height: 14px;
      background-image: url(/assets/images/common/slash.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-left: 0.5rem;
    }
  }
  font-size: 3.6rem;
  font-weight: bold;
  line-height: calc(63 / 36);
  letter-spacing: 0.05em;
  @include r.sp {
    font-size: 2.4rem;
    line-height: calc(36 / 24);
    letter-spacing: 0;
  }
}

// slash2つ
.c-ttl3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 5rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  @include r.sp {
    gap: 5px;
    font-size: 3rem;
  }
  &:before {
    content: '';
    display: inline-block;
    width: 65px;
    height: 54px;
    background-image: url(/assets/images/common/slash2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    @include r.sp {
      width: 38px;
      height: 32px;
    }
  }
}
