@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-breadcrumb {
  font-family: 'Barlow', sans-serif;
  margin: 10px 0;
  &__list {
    display: flex;
    align-items: center;
    gap: 20px;
    @include r.sp {
      gap: 10px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 2rem;
    line-height: calc(32 / 20);
    @include r.sp {
      gap: 10px;
      font-size: 1.6rem;
    }
    &:not(:last-child) {
      &:after {
        content: '>';
        display: inline-block;
      }
    }
    a {
      transition: all 0.2s;
      &:hover {
        color: #c8161e;
      }
    }
    &.current {
      font-weight: bold;
      color: #c8161e;
    }
  }
}
