@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.c-mv2 {
  width: 100vw;
  height: 600px;
  background-image: url(/assets/images/common/mv2.png);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: cover;
  @include r.sp {
    height: 550px;
    background-image: url(/assets/images/common/mv2_sp.png);
  }
  .inner {
    height: 100%;
    position: relative;
  }
  .c-ttl1 {
    position: absolute;
    bottom: 96px;
    left: 40px;
    @include r.sp {
      font-size: 6rem;
      bottom: 35px;
      left: 30px;
    }
    &__ja {
      @include r.sp {
        font-size: 2.4rem;
      }
      &:before {
        @include r.sp {
          width: 20px;
          height: 24px;
        }
      }
    }
  }
}
