@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.l-header {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  &__logo {
    width: 100px;
    margin-top: 42px;
    margin-left: 50px;
    @include r.sp {
      width: 60px;
      margin-top: 10px;
      margin-left: 15px;
      position: relative;
      z-index: 10;
    }
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 29px;
    margin-right: 257px;
    @include r.pc {
      // width: 625px;
      max-width: 625px;
      height: 70px;
      background: #fff;
      // border: 1px solid #000;
      // border-top: none;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 0 40px;
      position: absolute;
      top: 0;
      right: 0;
      transition: all 0.2s 0.2s;
    }
    &.hover {
      @include r.pc {
        border-bottom: 1px solid transparent;
        border-radius: 0;
      }
    }
    @media (max-width: 800px) {
      gap: 16px;
    }
    @include r.sp {
      flex-direction: column;
      justify-content: initial;
      align-items: initial;
      gap: 10px;
      width: 100dvw;
      height: 100dvh;
      overflow-y: scroll;
      background: #fff;
      padding-top: 100px;
      margin: 0;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-110%);
      transition: all 0.8s;
    }
    &.is-open {
      @include r.sp {
        transform: translate(0);
      }
    }
    &__item {
      @include r.sp {
        width: 265px;
        margin: 0 auto;
      }
      &__link {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: 0.2em;
        color: #111;
        padding: 5px;
        transition: all 0s 0.2s;
        position: relative;
        z-index: 1;
        &:before {
          content: '';
          display: inline-block;
          width: 25px;
          height: 25px;
          background-repeat: no-repeat;
          background-size: contain;
          transition: all 0s 0.2s;
        }
        &:after {
          @include r.pc {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: -5px;
            z-index: -1;
            width: 0;
            background: #c8161e;
            border-radius: 20px;
            transition: all 0.2s 0.2s;
          }
        }
        &:hover {
          &.service {
            &:before {
              @include r.pc {
                background-image: url(/assets/images/common/icon-service-white.svg);
              }
            }
          }
          &.paper {
            &:before {
              @include r.pc {
                background-image: url(/assets/images/common/icon-paper-white.svg);
              }
            }
          }
          &.qa {
            &:before {
              @include r.pc {
                background-image: url(/assets/images/common/icon-qa-white.svg);
              }
            }
          }
          @include r.pc {
            color: #fff;
          }
          &:after {
            @include r.pc {
              width: calc(100% + 10px);
            }
          }
        }
        &.service {
          &:before {
            background-image: url(/assets/images/common/icon-service.svg);
          }
        }
        &.paper {
          &:before {
            background-image: url(/assets/images/common/icon-paper.svg);
          }
        }
        &.qa {
          &:before {
            background-image: url(/assets/images/common/icon-qa.svg);
          }
        }
      }
      &--service {
        &:hover {
          .l-header {
            &__sub {
              @include r.pc {
                opacity: 1;
                visibility: visible;
                // transform: translate(0);
              }
            }
          }
        }
      }
    }
  }
  &__sub {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 7px;
    padding-left: 30px;
    @include r.pc {
      opacity: 0;
      visibility: hidden;
      flex-direction: column;
      width: 625px;
      background: #fff;
      // border: 1px solid #000;
      // border-top: none;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      padding: 10px 30px 30px;
      position: absolute;
      top: 70px;
      left: 0;
      z-index: -1;
      // transform: translateY(-110%);
      transition: all 0.2s 0.2s;
    }
    @include r.sp {
      margin-top: 8px;
    }
    &__item {
      @include r.pc {
        padding-left: 2em;
      }
      @include r.sp {
        width: 100%;
      }
      &.u-inline {
        @include r.sp {
          width: auto;
        }
      }
      a {
        display: inline-flex;
        align-items: baseline;
        gap: 5px;
        font-weight: bold;
        line-height: calc(24 / 16);
        transition: all 0.2s;
        &:before {
          content: '◯';
          font-size: 1rem;
          width: 1rem;
          height: auto;
          transform: translateY(-2px);
        }
        &:hover {
          color: #c8161e;
        }
      }
    }
    .u-txtRed {
      font-weight: bold;
      line-height: calc(24 / 16);
      color: #c8161e;
      @include r.pc {
        padding-left: 1em;
      }
    }
    hr {
      width: 100%;
      height: 1px;
      border-top: 1px solid #000;
      @include r.pc {
        max-width: 333px;
      }
    }
  }
  &__contact {
    width: 190px;
    background: #000;
    // border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Barlow', sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.2em;
    color: #fff;
    padding: 20px;
    position: absolute;
    right: 40px;
    transition: all 0.4s;
    @include r.pc {
      height: 70px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      top: 0;
    }
    @include r.sp {
      width: 150px;
      border-radius: 25px;
      font-size: 1.2rem;
      padding: 16px;
      top: 15px;
      right: 70px;
    }
    &:before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-image: url(/assets/images/common/mail.svg);
      background-repeat: no-repeat;
      background-size: contain;
      margin-right: 5px;
    }
    &:hover {
      background: #c8161e;
    }
    span {
      color: #fff;
    }
  }
  .spMenu {
    @include r.sp {
      width: 50px;
      height: 50px;
      position: absolute;
      top: 15px;
      right: 15px;
    }
    &.is-open {
      .off {
        display: none;
      }
      .on {
        display: block;
      }
    }
    .on {
      display: none;
    }
  }
}
